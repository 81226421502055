import { normalize } from 'normalizr';
import axios from 'axios';

import schemas from '../core/schemas';

import * as Actions from '../action-types/entities';

export const fetchEntitiesRequested = () => ({
  type: Actions.FETCH_ENTITIES_REQUESTED,
});

export const fetchEntitiesReceived = entities => ({
  type: Actions.FETCH_ENTITIES_RECEIVED,
  payload: { entities },
});

export const fetchEntitiesFailed = () => ({
  type: Actions.FETCH_ENTITIES_FAILED,
});

export const fetchEntities = () => dispatch => {
  dispatch(fetchEntitiesRequested());

  return axios
    .get(BAO_EXPORT)
    .then(({ data }) => {
      const normalizedData = normalize(data, [schemas.method]);
      return dispatch(fetchEntitiesReceived(normalizedData.entities));
    })
    .catch(err => {
      dispatch(fetchEntitiesFailed());
      throw err;
    });
};

const shouldFetchEntities = state => {
  if (state.isPending) return false;
  if (!state.receivedAt) return true;

  const now = new Date();
  return now - state.receivedAt > 7200000; // 2 hours
};

export const fetchEntitiesIfRequired = () => (dispatch, getState) => {
  const { entities } = getState();

  return shouldFetchEntities(entities.metaData) ? dispatch(fetchEntities()) : Promise.resolve();
};
